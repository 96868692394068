@import '../node_modules/bootstrap/dist/css/bootstrap.min.css';

*{
  padding:0;
  margin:0;
  box-sizing:border-box;
}

body{
  width:100%;
  height:100vh;
  line-height: 1;
  overflow-x: hidden;
}

input::placeholder{
  font-size:14px;
}
label{
  font-size:14px;
  font-weight: 500;
}
select{
  font-size:14px !important;
}
.form-control:focus{
  border:1px solid black;
  box-shadow:none;
  -webkit-box-shadow:none;
}
.btn{
  &:focus{
    box-shadow:none;
  }
}

.navbar{
  background-color:#83bb45;
}
ul{
  li{
      list-style:none;
  }
}

/* styling for date range picker */
.rs-picker-menu {
  z-index: 999999 !important;
  margin-top:5px;
 }
.date-range-picker{
   width:100%;
 
}
.rs-picker-default:not(.rs-picker-disabled) .rs-btn:hover,
.rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle:hover,
.rs-picker-default:not(.rs-picker-disabled) .rs-btn.active,
.rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle.active {
  border-color: #83bb45 !important;
}

.rs-picker-toolbar-right-btn-ok {
 
  background-color: #83bb45 !important;
}
.rs-calendar-table-cell-selected .rs-calendar-table-cell-content {
  border-color: #83bb45 !important;
  background-color: #83bb45 !important;
}
.rs-calendar-table-cell-is-today .rs-calendar-table-cell-content {
  border: 1px solid #83bb45 !important;
}
.rs-calendar-table-cell-content:hover {
  background-color: #83bb45 !important;
}

.rs-calendar-table-cell-in-range::before {
  background-color: #84bb451c !important;
}
.rs-picker-has-value .rs-btn .rs-picker-toggle-value,
.rs-picker-has-value .rs-picker-toggle .rs-picker-toggle-value {
  color: black !important;
}

//toast
.custom-toast{
  color:black !important;
}

.form-group {
  margin-bottom: 1rem;
}

.ml-2 {
  margin-left: 0.5rem!important;
}

.img-title-container {
  text-align: center;
}