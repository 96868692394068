.loading-dots > div{
    width:0.5rem;
    height:0.5rem;
    background-color:rgba(0, 0, 0, 0.637);
    border-radius: 50%;
    display:inline-block;
    animation:1.5s bounce infinite ease-in-out both;
}
.loading-dots .bounce{
    animation-delay: -0.30s;
}
.loading-dots .bounce2{
    animation-delay: -0.15s;
}

@keyframes bounce {
    0%,80%,100%{
        transform: scale(0);
    }
    40%{
        transform: scale(1);
    }
}