.login-section {
  min-height: 100vh;
  background-image: url("../../assets/images/golfplatz.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  .greenway-logo {
    justify-content: center;
    display: flex;
    padding-bottom: 5%;

    img {
      width: 70%;
      margin-left: 8%;
    }
  }

  .container {
    justify-content: center;
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .login-card {
    width: 400px;
    min-height: 300px;
    border: none;
    background: rgba(131, 187, 69, 0.7);
    border-radius: 15px;

    .forgot-password {
      margin-top: 8%;
      justify-content: center;
      display: flex;

      a {
        color: #ffff;
      }
    }

    .card-header {
      background-color: #7eac54;
      color: #ffffff;
      border: none;
    }
    .card-body {
      padding: 8% 10% 8%;
    }

    .login-buttons {
      color: #7eac54;
      background-color: #ffffff;
      width: 40%;
      margin-top: 5%;
    }

    .login-items {
      justify-content: space-between;
      display: flex;
    }

    label {
      color: #ffffff;
    }
  }

  .dropdown {
    margin-top: 8%;
    justify-content: center;
    display: flex !important;
  }

  .btn-primary {
    background-color: #ffffff;
    color: #7eac54;
  }

  .btn-primary.dropdown-toggle {
    &:hover {
      background-color: #ffffff;
    }
    background-color: #ffffff;
    border-color: #ffffff;
    color: #7eac54;
    width: 50%;
    display: inline-flex;
    min-width: fit-content;
    justify-content: center;
  }

  button.dropdown-toggle.btn.btn-primary {
    background-color: #ffffff;
    border-color: #ffffff;
  }

  .dropdown-item {
    display: inline-flex;
    color: #7eac54;
    padding-left: 0.75rem;
    justify-content: center;

    img {
      margin-right: 15%;
    }
  }

  .dropdown-toggle::after {
    display: none;
  }

  .lang-button {
    display: inline-flex;
    justify-content: center;
    img {
      margin-right: 15%;
      max-width: 100%;
      max-height: 100%;
    }
  }

  .dropdown-menu {
    min-width: 0px;
    width: 50%;
    padding: 0px;
  }
}
@media (max-width: 599px) {
  .login-section {
    h2 {
      font-size: 3rem;
    }
    .login-card {
      width: 100%;
    }
  }
}

@media (min-width: 600px) {
}

@media (min-width: 900px) {
}
@media (min-width: 1200px) {
}
@media (min-width: 1500px) {
}

.btn-outline-success {
  margin-left: 0.3rem;
}
