.user-view-container {
  width: 100%;
  min-height: 100vh;
  background-color: rgba(0, 128, 0, 0.404);
}
.map-container {
  position: relative;

  .loch {
    position: absolute;
    top: 0;
    display: flex;
    justify-content: center;
    z-index: 99;
  }
}
.loading-screen {
  position: absolute;
  top: 0;
  z-index: 99;
  width: 100%;
  min-height: 100vh;
  background-color: rgba(0, 0, 0, 0.719);
  display: flex;
  justify-content: center;
  align-items: center;
}
.loading-card {
  width: 400px;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  p {
    font-size: 1rem;
    font-weight: bold;
  }
}

.menu-buttons {
  display: flex;
  margin-top: 60vh;
  flex-direction: column;

  .dropdown-toggle::after {
    display: none;
  }

  .btn-primary {
    background-color: #f8f9fa;
    color: #000;
    display: inline-flex;
    flex-direction: row;
    margin: 0.2rem;
    padding: 0.5rem 1rem;
    justify-content: center;
    width: 97%;

    img {
      margin-right: 15%;
    }
  }

  .dropdown-item {
    display: inline-flex;
    justify-content: center;
    color: #000;

    img {
      margin-right: 15%;
    }
  }

  .btn-primary.dropdown-toggle {
    background-color: #f8f9fa;
    border-color: #f8f9fa;
    color: #000;
    &:disabled,
    &:hover,
    &:active {
      background-color: #f8f9fa;
      border-color: #f8f9fa;
      color: #000;
    }
  }

  .dropdown-menu.show {
    width: 97%;
    background-color: #f8f9fa;
    border-color: #f8f9fa;
  }
}

.offcanvas {
  overflow-y: scroll;
}

.btn-light {
  margin: 0.2rem;
}

#ctl-button {
  background: none rgb(255, 255, 255);
  border: 0px;
  margin: 10px;
  padding: 0px;
  text-transform: none;
  appearance: none;
  position: absolute;
  cursor: pointer;
  user-select: none;
  border-radius: 2px;
  height: 40px;
  width: 40px;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
  overflow: hidden;
  top: 0px;
  right: 5vh;
  display: grid;
}

#gear-icon,
#gear-button {
  background: none rgb(255, 255, 255);
}

#no-aoi {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}
