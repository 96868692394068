hr.step {
  width: 60%;
  margin-left: 5%;
  white-space: nowrap;
}

.scale {
  scale: 0.65;
}

.steps {
  scale: 0.6;
}

.gradient {
  width: 30%;
  height: 123px;
  margin-left: 2%;
  margin-right: 7%;
}

.legend-container {
  background-color: #ffffff;
  max-height: 150px;
  width: 90px;
  z-index: 4;
  position: fixed;
  right: 0.7%;
  top: 60px;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
}

.steps {
  width: 57%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.step {
  display: inline-flex;
  flex-direction: row;
}

#legend-header {
  transform: rotate(180deg);
  writing-mode: vertical-lr;
  font-size: x-small;
  margin-left: 6%;
}
