.modal-dialog.modal-lg.modal-dialog-centered, .modal-dialog.modal-dialog-centered {
    max-width: 80%;
}


.script-upload-footer {
    display: inline-flex;
    align-items: center;
}

#memberships-aois-selection {
    padding-bottom: 10px;
}