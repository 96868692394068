.side-panel-container {
  background-color: white;
  width: 24%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 20;
  transform: translateX(-100%);
  transition: transform 0.5s ease-in;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.034);
}

.side-panel-container.active {
  transform: translateX(0%);
}

.visualization-container {
  overflow-y: auto;
  width: 100%;
  height: 83vh;
  padding-bottom: 2rem;

  width: 100%;
  min-height: 200px;
  button {
    margin-right: 1px;
    background-color: #83bb45;
    color: #ffffff;
    &:hover {
      color: #ffffff;
    }
  }
  .dropdown-menu.show {
    background-color: #83bb45;
  }
}

.toggle-side-panel-btn {
  position: absolute;
  top: 3.5rem;
  right: -1.63rem;
  z-index: 20;
  cursor: pointer;
  background-color: #ffffff;
  border: 1px solid #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25px;
  height: 25px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
}
.side-panel-card-header {
  background-color: #83bb45;
  color: #ffffff;
  cursor: pointer;
}
.grid-view-icon {
  font-size: 20px;
  margin-right: 1.3rem;
  cursor: pointer;
}
.grid-view-modal-body {
  overflow-y: auto;
  height: 80vh;
  width: 100%;
}

.btn-group {
  button.dropdown-toggle.btn.btn-primary {
    box-shadow: none;
    -webkit-box-shadow: none;
    background-color: #83bb45;
    border-color: transparent;
  }

  button.dropdown-item.dropdown-item {
    &:disabled,
    &:hover,
    &:active {
      background-color: #ffffff;
    }
  }
}

#dropdown-geo-button,
#dropdown-sub-button {
  // width:100%;
  // color:black;
  // background-color: white;
  // border:1px solid black;
  &:focus {
    box-shadow: none;
    -webkit-box-shadow: none;
    background-color: #83bb45;
    border-color: transparent;
  }
  max-height: 300px;
  overflow-y: scroll;
  margin-right: 1px;
  background-color: #83bb45;
  color: #ffffff;
  &:disabled,
  &:hover,
  &:active {
    color: #ffffff;
  }
  border-color: transparent;
}

.button.dropdown-toggle.btn.btn-primary .btn-primary.dropdown-toggle {
  background-color: #83bb45;
  border-color: transparent;
  color: #fff;

  &:disabled,
  &:hover,
  &:active,
  &:focus {
    box-shadow: none;
    -webkit-box-shadow: none;
    background-color: #83bb45;
    border-color: transparent;
  }
}

#reset-map-button {
  background-color: transparent;
  border-color: transparent;
  color: var(--rs-text-primary);
  font-size: 14px;

  &:hover {
    color: rgb(0, 0, 0);
  }
}

#form-check-label {
  margin-top: 0.1em;
}

label.form-check-label {
  padding-left: 0.5rem;
}

.form-switch {
  padding-left: 3.2em;
}

.card-body {
  min-height: 200px;
}
.chart-image {
  height: 180px;
}

.dropdown-menu {
  max-height: 30rem;
  overflow-y: auto;
}

@media (max-width: 599px) {
  .side-panel-container {
    width: 90%;
  }
}

@media (min-width: 600px) {
  .side-panel-container {
    width: 40%;
  }
}

@media (min-width: 900px) {
}
@media (min-width: 1200px) {
  .side-panel-container {
    width: 28%;
  }
}
@media (min-width: 1500px) {
}

.accordion-body {
  padding: 0;
}

.accordion-button {
  background-color: #83bb45;
  color: #fff;
  cursor: pointer;
  padding-top: 0.05rem;
  padding-bottom: 0.05rem;
}

.rectangle {
  display: inline-block;
  width: 3.5vh;
  height: 3.5vh;
  background: rgb(119, 115, 115);
  border-style: solid;
  border-color: rgb(0, 0, 0);
  border-width: 0.1vh;
  margin-left: 25vh;
}

.circle {
  width: 2.5vh;
  height: 2.5vh;
  border-radius: 50%;
  border-style: solid;
  border-color: rgb(154, 153, 153);
  border-width: 0.1vh;
}

.traffic-lights {
  right: 20%;
}

.nav-tabs .nav-link {
  color: var(--rs-text-primary);

  &:active, &:hover {
    color: var(--rs-text-primary);
  }
}

.nav-item {
  cursor: not-allowed;
}
