.table td, .table th {
    vertical-align: middle;
    min-width: 7rem;
}

.create {
    float: right;
}

.table {
    word-break: break-word;
}

#create-ic-script {
    float: right;
}