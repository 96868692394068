#admin-navbar {
  padding-left: 1rem;
  padding-right: 1rem;
}

#navigation-bar {
  justify-content: end;
}

.navbar-light .navbar-nav .nav-link {
  color: #fff;

  &:hover {
    color: #fff;
  }
}

.admin-img-container {
  .admin-img {
    width: 70px;
    height: 40px;
    clip-path: circle();
    cursor: pointer;
  }
}
