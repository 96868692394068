.profile-ändern-card{
    min-height: 300px;
    width:100%;
    border:1px solid black;
    ul{
        display:flex;
        justify-content:center;
        flex-direction: column;
        height:100%;
        li{
            color:#83bb45;
            font-size:1.5rem;
            line-height: 1.4;
        }
    }
}
.profile-ändern-img-container{
    position:relative;

    .img-title-container{
        position:absolute;
        top:0;
        width:100%;
        background-color:#83bb45;
        z-index:10;
        cursor: pointer;
        p{
            color:#ffffff;
            display: inline-block;
            line-height: 50px;
            text-align:center;
            padding:0;
            margin: 0 auto;
           
        }
    }
}
.aoi-ändern-img-container, 
.scripts-ändern-img-container, 
.mitgliedschaft-ändern-img-container{
    position:relative;

    .img-title-container{
        position:absolute;
        bottom:0;
        width:100%;
        background-color:#83bb45;
        z-index:10;
        cursor: pointer;

        p{
            color:#ffffff;
            display: inline-block;
            line-height: 50px;
            text-align:center;
            padding:0;
            margin: 0 auto;
           
        }
    }
}
.profile-ändern-img,.aoi-ändern-img,
.scripts-ändern-img,.mitgliedschaft-ändern-img{
    min-height:300px;
    max-width:100%;
}
.gold-member-container{
    width:100%;
    background-color:#d1c12f;
    text-align:center;

    p{
        color:#ffffff;
        display: inline-block;
        line-height: 50px;
        text-align:center;
        padding:0;
        margin: 0 auto;
       
    }
}
.profil-löschen-btn{
    border:1px solid red;
}

.loader {
    vertical-align: middle;
}

#show-sessions-button {
    justify-content: center;
    display: flex;
}
